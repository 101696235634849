var key = 0;
var screen = 0;
var glitchCount = 0;
var english = "10⋗ΣΠΣ木৲XƆ⋏⋌とぐぃムニウゾ";

export function matrixRain() {
    var c = document.getElementById("matrixRain");
    var ctx = c.getContext("2d");

    //canvas full screen
    c.height = window.innerHeight;
    c.width = window.innerWidth;

    //converting the string into an array of single characters
    english = english.split("");

    var font_size = 12;
    var columns = c.width/font_size; //number of columns for the rain

    //an array of drops - one per column
    var drops = [];

    //x below is the x coordinate
    //1 = y co-ordinate of the drop(same for every drop initially)
    for(var x = 0; x < columns; x++)
        drops[x] = 1; 

    //drawing the characters
    function draw()
    {
        //Black BG for the canvas
        //translucent BG to show trail
        ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
        ctx.fillRect(0, 0, c.width, c.height);

        ctx.fillStyle = "#E21330"; //green text #0F0 //red #cf102b
        ctx.font = font_size + "px arial";
    
        //looping over drops
        for(var i = 0; i < drops.length; i++)
        {
            //a random character to print
            var text = english[Math.floor(Math.random()*english.length)];
            //x = i*font_size, y = value of drops[i]*font_size
            ctx.fillText(text, i*font_size, drops[i]*font_size);
    
            //sending the drop back to the top randomly after it has crossed the screen
            //adding a randomness to the reset to make the drops scattered on the Y axis
            if(drops[i]*font_size > c.height && Math.random() > 0.975)
            drops[i] = 0;
    
            //incrementing Y coordinate
            drops[i]++;
        }
    }
    setInterval(draw, 60);
}

export function loadMenu(event) {
    var cmdStatus = document.getElementById("cmdApp").style.display;
    if (cmdStatus = 'block' && event.keyCode === 13) {
        if(key === 0) {
            document.getElementById('streamPageApp').style.display = 'block';
            document.getElementById('streamPageApp').style.zIndex = '2';
        }
        else if(key === 1) {
            document.getElementById('storePageApp').style.display = 'block';
            document.getElementById('storePageApp').style.zIndex = '2';
            document.getElementById('storePageApp').style.opacity = '1';
        }
        else if(key === 2) {
            document.getElementById('aboutPageApp').style.display = 'block';
            document.getElementById('aboutPageApp').style.zIndex = '2';
        }
        else if(key === 3) {
            document.getElementById('contactPageApp').style.display = 'block';
            document.getElementById('contactPageApp').style.zIndex = '2';
        }
    }

    else if (cmdStatus = 'block' && event.keyCode === 40) {
        if(key === 3) {
            key--;
        }
        key += 1;
        cursorMove();
        if(key === 0) {
            document.getElementById('cursor').style.left = '57%';
            document.getElementById('menu1').style.borderColor = 'white';
            document.getElementById('menu2').style.borderColor = 'black';
            document.getElementById('menu3').style.borderColor = 'black';
            document.getElementById('menu4').style.borderColor = 'black';
        }
        
        else if(key === 1) {
            document.getElementById('cursor').style.left = '51%';
            document.getElementById('menu1').style.borderColor = 'black';
            document.getElementById('menu2').style.borderColor = 'white';
            document.getElementById('menu3').style.borderColor = 'black';
            document.getElementById('menu4').style.borderColor = 'black';
        }
    
        else if(key === 2) {
            document.getElementById('cursor').style.left = '51%';
            document.getElementById('menu1').style.borderColor = 'black';
            document.getElementById('menu2').style.borderColor = 'black';
            document.getElementById('menu3').style.borderColor = 'white';
            document.getElementById('menu4').style.borderColor = 'black';
        }
    
        else if(key === 3) {
            document.getElementById('cursor').style.left = '54%';
            document.getElementById('menu1').style.borderColor = 'black';
            document.getElementById('menu2').style.borderColor = 'black';
            document.getElementById('menu3').style.borderColor = 'black';
            document.getElementById('menu4').style.borderColor = 'white';
        }
    }

    else if (cmdStatus = 'block' && event.keyCode === 38) {
        if(key === 0) {
            key++;
        }
        key -= 1;
        cursorMove();
        if(key === 0) {
            document.getElementById('cursor').style.left = '57%';
            document.getElementById('menu1').style.borderColor = 'white';
            document.getElementById('menu2').style.borderColor = 'black';
            document.getElementById('menu3').style.borderColor = 'black';
            document.getElementById('menu4').style.borderColor = 'black';
        }
        
        else if(key === 1) {
            document.getElementById('cursor').style.left = '51%';
            document.getElementById('menu1').style.borderColor = 'black';
            document.getElementById('menu2').style.borderColor = 'white';
            document.getElementById('menu3').style.borderColor = 'black';
            document.getElementById('menu4').style.borderColor = 'black';
        }
    
        else if(key === 2) {
            document.getElementById('cursor').style.left = '51%';
            document.getElementById('menu1').style.borderColor = 'black';
            document.getElementById('menu2').style.borderColor = 'black';
            document.getElementById('menu3').style.borderColor = 'white';
            document.getElementById('menu4').style.borderColor = 'black';
        }
    
        else if(key === 3) {
            document.getElementById('cursor').style.left = '54%';
            document.getElementById('menu1').style.borderColor = 'black';
            document.getElementById('menu2').style.borderColor = 'black';
            document.getElementById('menu3').style.borderColor = 'black';
            document.getElementById('menu4').style.borderColor = 'white';
        }
    }
}

export function cursorMove() {
        var d = document.getElementById('cursor');
        d.style.top = (2.4 * key) + 'vh';
}

export function glitch() {
    document.getElementById("linkBlocker").style.display = "block";
    document.getElementById("aboutText").style.display = "none";
    document.getElementById("aboutText2").style.display = "block";
    document.getElementById("aboutText3").style.display = "block";
    setTimeout(() => {
        document.getElementById("linkBlocker").style.display = "none";
        document.getElementById("aboutText").style.display = "block";
        document.getElementById("aboutText2").style.display = "none";
        document.getElementById("aboutText3").style.display = "none";
    }, 4000);
}

export function closeScreen() {
    var screenOff = document.getElementById("screenIsOff");
    var screenOn = document.getElementById("screenIsOn");

    if (screen === 1) {
        screenOn.style.display = "none";
        screenOff.style.display = "block";
        document.getElementById("powerbuttonled").style.backgroundColor = "red";
        document.getElementById("powerbuttonled").style.boxShadow = "0px 0px 5px 2.5px red";
        screen = 0;
    }

    else {
        glitchCount++;

        if (glitchCount > 3) {
            glitch();
            glitchCount = 0;
        }
        else {}

        document.getElementById("powerbuttonled").style.backgroundColor = "#0e8f15";
        document.getElementById("powerbuttonled").style.boxShadow = "0px 0px 5px 2.5px #109e17";
        screenOff.style.display = "none";
        screenOn.style.display = "block";
        screen = 1;
    }

    document.getElementById("powerbutton").style.border = "outset";
    setTimeout(() => {
        document.getElementById("powerbutton").style.border = "inset";
    }, 400);
}