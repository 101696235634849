import React, { Component } from 'react';
import { matrixRain } from './helperFunctions';

class Menu extends Component {
    constructor(props){
        super(props);
    
        this.mouseOver = this.mouseOver.bind(this);
        this.mouseOver2 = this.mouseOver2.bind(this);
        this.mouseOver3 = this.mouseOver3.bind(this);
        this.mouseOver4 = this.mouseOver4.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
        this.clickHandler2 = this.clickHandler2.bind(this);
        this.clickHandler3 = this.clickHandler3.bind(this);
        this.clickHandler4 = this.clickHandler4.bind(this);

    }

    componentDidMount() {
        // Call matrixRain function when the component loads
        matrixRain();
    }

    mouseOver() {
        document.getElementById('cursor').style.left = '57%';
        document.getElementById('menu1').style.borderColor = 'white';
        document.getElementById('menu2').style.borderColor = 'black';
        document.getElementById('menu3').style.borderColor = 'black';
        document.getElementById('menu4').style.borderColor = 'black';
        document.getElementById('cursor').style.top = (0) + 'vh';
    }

    mouseOver2() {
        document.getElementById('cursor').style.left = '51%';
        document.getElementById('menu1').style.borderColor = 'black';
        document.getElementById('menu2').style.borderColor = 'white';
        document.getElementById('menu3').style.borderColor = 'black';
        document.getElementById('menu4').style.borderColor = 'black';
        document.getElementById('cursor').style.top = (2.4) + 'vh';
    }

    mouseOver3() {
        document.getElementById('cursor').style.left = '51%';
        document.getElementById('menu1').style.borderColor = 'black';
        document.getElementById('menu2').style.borderColor = 'black';
        document.getElementById('menu3').style.borderColor = 'white';
        document.getElementById('menu4').style.borderColor = 'black';
        document.getElementById('cursor').style.top = (4.8) + 'vh';
    }

    mouseOver4() {
        document.getElementById('cursor').style.left = '54%';
        document.getElementById('menu1').style.borderColor = 'black';
        document.getElementById('menu2').style.borderColor = 'black';
        document.getElementById('menu3').style.borderColor = 'black';
        document.getElementById('menu4').style.borderColor = 'white';
        document.getElementById('cursor').style.top = (7.2) + 'vh';
    }

    clickHandler(event) {
        document.getElementById('streamPageApp').style.display = 'block';
        document.getElementById('streamPageApp').style.zIndex = '2';
        
        event.stopPropagation();
    }

    clickHandler2(event) {
        document.getElementById('storePageApp').style.display = 'block';
        document.getElementById('storePageApp').style.zIndex = '2';
        document.getElementById('storePageApp').style.opacity = '1';

        event.stopPropagation();
    }

    clickHandler3(event) {
        document.getElementById('aboutPageApp').style.display = 'block';
        document.getElementById('aboutPageApp').style.zIndex = '2';

        event.stopPropagation();
    }

    clickHandler4(event) {
        document.getElementById('contactPageApp').style.display = 'block';
        document.getElementById('contactPageApp').style.zIndex = '2';

        event.stopPropagation();
    }

    render() { 
        return (
            <div id="menu">
                <div id="cmd-program-line-tag">
                        C:\Users\AaronKumar{'>'}
                </div>
                <div id="cursor">▂</div>
                <div id="menu1" onClick={this.clickHandler} onMouseOver={this.mouseOver}>MFKN_AK Stream</div>
                <div id="menu2" onClick={this.clickHandler2} onMouseOver={this.mouseOver2}>MFKN Store</div>
                <div id="menu3" onClick={this.clickHandler3} onMouseOver={this.mouseOver3}>About MFKN</div>
                <div id="menu4" onClick={this.clickHandler4} onMouseOver={this.mouseOver4}>Contact Form</div>
            </div>  
        );
    }
}
 
export default Menu;